<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row
      justify="space-between"
      class="head-search ma-0">
      <v-col cols="auto">
        <v-select
          v-model="warehouseSelected"
          :items="filteredWarehouse"
          :menu-props="{ offsetY: true }"
          item-value="name"
          item-text="name"
          label="Warehouses"
          class="select-warehouse"
          return-object
          auto-select-first
          outlined
          hide-details
          dense />
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-tabs v-model="selectTab">
          <v-tab> ทั้งหมด </v-tab>
          <v-tab> รอจัดการ </v-tab>
          <v-tab> รอรับเข้า </v-tab>
          <v-tab> สำเร็จ </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="data"
          :options.sync="options"
          :items-per-page="options.itemsPerPage"
          :loading="loading"
          :server-items-length="total"
          :height="380"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 20]
          }"
          class="elevation-1 mb-5"
          @update:items-per-page="optionsUpdated()"
          @update:page="optionsUpdated()"
          @update:sort-by="optionsUpdated()"
          @update:sort-desc="optionsUpdated()">
          <template v-slot:item.action="{ item }">
            <action-btn-group
              :tab="tabValues[selectTab]"
              @onAction="(type) => { handleActionBtn(type, item) }" />
          </template>
          <template v-slot:item.time="{ item }">
            {{ showDateDiff(item.updatedAt, now) }}
          </template>
          <template v-slot:item.product="{ item }">
            <product
              v-for="(sku, index) of item.skus"
              :key="index"
              :product="sku" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ClickCollectProvider from '@/resources/ClickCollectProvider'
import ActionBtnGroup from '../components/ActionBtnGroup.vue'
import Product from '../components/Product.vue'

const ClickCollectService = new ClickCollectProvider()

export default {
  name: 'StockPickingList',
  components: {
    ActionBtnGroup,
    Product
  },
  data () {
    return {
      getting: false,
      loading: false,
      total: 0,
      data: [],
      now: new Date(),
      warehouseSelected: {},
      tabValues: ['all', 'pending', 'shipped', 'completed'],
      selectTab: 0,
      headers: [
        {
          text: 'Order Id',
          value: 'reference.value',
          sortable: false,
          width: 200
        },
        {
          text: 'Product',
          value: 'product',
          sortable: false
        },
        {
          text: 'From',
          value: 'warehouse.name',
          sortable: false
        },
        {
          text: 'To',
          value: 'targetWarehouse.name',
          sortable: false
        },
        {
          text: 'Time',
          value: 'time',
          sortable: false
        },
        {
          text: 'Action',
          value: 'action',
          sortable: false
        }
      ],
      options: {
        page: 1,
        itemsPerPage: 5,
        sortBy: ['createdAt'],
        sortDesc: [true]
      }
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    queryWarehouse () {
      return this.$route.query.warehouse ? this.$route.query.warehouse : this.filteredWarehouse[0].code
    },
    filteredWarehouse () {
      return this.mapWarehouse.filter((wh) => wh.code !== 'main')
    },
    queryTab () {
      return this.$route.query.tab ? parseInt(this.$route.query.tab) : 0
    }
  },
  watch: {
    warehouseSelected () {
      this.$router.push({
        name: 'StockPickingList',
        query: {
          warehouse: this.warehouseSelected.code,
          tab: this.selectTab
        }
        }, () => {})
      this.getStockPicking()
    },
    selectTab () {
      this.$router.push({
        name: 'StockPickingList',
        query: {
          warehouse: this.warehouseSelected.code,
          tab: this.selectTab
        }
        }, () => {})
      this.getStockPicking()
    }
  },
  created () {
    this.init()
  },
  mounted () {
    setInterval(() => {
      this.now = new Date()
    }, 2000)
  },
  methods: {
    ...mapActions({
      setErrorPage: 'Components/setErrorPage',
      setSnackbar: 'Components/setSnackbar'
    }),
    diff (time, now) {
      const dTime = this.$dayjs(time)
      const dNow = this.$dayjs(now)
      return this.$dayjs(dNow).diff(dTime, 'seconds')
    },
    showDateDiff (time, now) {
      const sec = this.diff(time, now)
      const minutes = Math.floor(sec / 60)
      if (minutes < 3) {
        return 'a few minites ago.'
      }
      if (minutes < 60) {
        return `${minutes} minites ago.`
      }
      if (minutes < 120) {
        return 'an hour ago.'
      }
      if (minutes < 1440) {
        const hours = Math.floor(minutes / 60)
        return `${hours} hours ago.`
      }
      if (minutes < 2880) {
        return 'day ago.'
      }
      const days = Math.floor(minutes / 1440)
      return `${days} days ago.`
    },
    handleActionBtn (type, item) {
      switch (type) {
        case 'transfer': this.transfer(item.id)
          break
        case 'receive': this.receive(item.id)
          break
        case 'print': this.print(item.id)
          break
        default:
          console.warn('handleActionBtn default')
      }
    },
    optionsUpdated () {
      this.getStockPicking()
    },
    async transfer (id) {
      try {
        this.loading = true
        const { data } = await ClickCollectService.transfer(id)
        if (data) {
          this.getStockPicking()
          this.setSnackbar({
              value: true,
              message: 'Transfer success',
              type: 'success'
          })
        }
      } catch (error) {
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
      }
    },
    async receive (id) {
      try {
        this.loading = true
        const { data } = await ClickCollectService.receive(id)
        if (data) {
          this.getStockPicking()
          this.setSnackbar({
              value: true,
              message: 'Receive success',
              type: 'success'
          })
        }
      } catch (error) {
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
      }
    },
    async print (id) {
      try {
        this.loading = true
        const { data } = await ClickCollectService.print(id)
        if (data) {
          this.setSnackbar({
              value: true,
              message: 'Receive success',
              type: 'success'
          })
        }
      } catch (error) {
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
      }
    },
    async getStockPicking () {
      if (!this.getting) {
        try {
          this.getting = true
          this.loading = true
          const { data } = await ClickCollectService.getStockPickingList({
            page: this.options.page,
            limit: this.options.itemsPerPage,
            sortBy: this.options.sortBy[0],
            sortOrder: this.options.sortDesc[0] === false ? 'asc' : 'desc',
            search: this.searchText,
            currentState: this.tabValues[this.selectTab],
            warehouse: this.warehouseSelected.code
          })
          this.total = data.total
          this.data = data.results
        } catch (error) {
          this.setErrorPage(error.code)
        } finally {
          this.loading = false
          this.getting = false
        }
      }
    },
    init () {
      this.warehouseSelected = this.mapWarehouse.find((r) => r.code === this.queryWarehouse)
      this.selectTab = this.queryTab
      this.getStockPicking()
    }
  }
}
</script>
