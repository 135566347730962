<template>
  <div>
    <div style="display: flex; justify-content: space-between;">
      <div style="font-weight: bold;">
        {{ product.code }}
      </div>
      <div>
        x {{ product.amount }}
      </div>
    </div>
    <div>
      {{ product.size }} {{ product.color }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductDetail',
  props: {
    product: {
      type: Object,
      required: true
    }
  }
}
</script>
