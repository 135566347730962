<template>
  <div>
    <div v-if="tab === 'pending'">
      <v-btn
        x-small
        outlined
        @click="action('print')">
        <v-icon size="14">
          mdi-printer
        </v-icon>
      </v-btn>
      <v-btn
        x-small
        outlined
        style="margin-left: 5px;"
        @click="action('transfer')">
        Transfer
      </v-btn>
    </div>
    <div v-else-if="tab === 'shipped'">
      <v-btn
        x-small
        outlined
        style="margin-left: 5px;"
        @click="action('receive')">
        Receive
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StockPickingActionGroup',
  props: {
    tab: {
      type: String,
      required: true
    }
  },
  methods: {
    action (type) {
      this.$emit('onAction', type)
    }
  }
}
</script>
