var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"products-list-page",attrs:{"fluid":""}},[_c('v-row',{staticClass:"head-search ma-0",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{staticClass:"select-warehouse",attrs:{"items":_vm.filteredWarehouse,"menu-props":{ offsetY: true },"item-value":"name","item-text":"name","label":"Warehouses","return-object":"","auto-select-first":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.warehouseSelected),callback:function ($$v) {_vm.warehouseSelected=$$v},expression:"warehouseSelected"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tabs',{model:{value:(_vm.selectTab),callback:function ($$v) {_vm.selectTab=$$v},expression:"selectTab"}},[_c('v-tab',[_vm._v(" ทั้งหมด ")]),_c('v-tab',[_vm._v(" รอจัดการ ")]),_c('v-tab',[_vm._v(" รอรับเข้า ")]),_c('v-tab',[_vm._v(" สำเร็จ ")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 mb-5",attrs:{"headers":_vm.headers,"items":_vm.data,"options":_vm.options,"items-per-page":_vm.options.itemsPerPage,"loading":_vm.loading,"server-items-length":_vm.total,"height":380,"footer-props":{
          itemsPerPageOptions: [5, 10, 15, 20]
        }},on:{"update:options":function($event){_vm.options=$event},"update:items-per-page":function($event){return _vm.optionsUpdated()},"update:page":function($event){return _vm.optionsUpdated()},"update:sort-by":function($event){return _vm.optionsUpdated()},"update:sort-desc":function($event){return _vm.optionsUpdated()}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('action-btn-group',{attrs:{"tab":_vm.tabValues[_vm.selectTab]},on:{"onAction":function (type) { _vm.handleActionBtn(type, item) }}})]}},{key:"item.time",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showDateDiff(item.updatedAt, _vm.now))+" ")]}},{key:"item.product",fn:function(ref){
        var item = ref.item;
return _vm._l((item.skus),function(sku,index){return _c('product',{key:index,attrs:{"product":sku}})})}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }