import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class ClickCollectProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_API)
  }

  getStockPickingList (query) {
    this.setHeader(getAuthToken())
    return this.get('/product-sku-stock-picking', query)
  }

  transfer (id) {
    this.setHeader(getAuthToken())
    return this.patch(`/product-sku-stock-picking/${id}/transfer`)
  }

  receive (id) {
    this.setHeader(getAuthToken())
    return this.patch(`/product-sku-stock-picking/${id}/receive`)
  }

  print (id) {
    this.setHeader(getAuthToken())
    return this.post(`/product-sku-stock-picking/${id}/print`)
  }
}

export default ClickCollectProvider
